/* global config, moment, angular */

'use strict';

angular.module('managerApp').controller('DiscountsController', (
        $scope, $routeParams, $location,$mdDialog, toast, discounts, procedureTools) => {

    const path = $location.path();
    $scope.newItem = {};
    $scope.config = config;
    $scope.discountId = $routeParams.id;
    $scope.discount = null;
    $scope.discounts = null;

    $scope.pagination = {
        currentPage: 1,
        maxSize: 5,
        itemsPerPage: 25,
        totalItems: 0,
        filters: {},
        pageChanged: () => {
            if ($scope.pagination.filters.clientType) {
                if ($scope.pagination.filters.clientType === '') {
                    delete $scope.pagination.filters.clientType;
                    $scope.pagination.filters.allClients = true;
                }
            }
            if ($scope.pagination.filters.service &&
                    $scope.pagination.filters.service === '') {
                delete $scope.pagination.filters.service;
                $scope.pagination.filters.allServices = true;
            }
            getDiscounts();
        }
    };

    $scope.showAddEditPopup = (item = {}, index) => {

        if (!item._id) {
            item = {
                validFrom: moment().format(config.date.defaultFormat),
                validTo: moment().format(config.date.defaultFormat),
                allClients: true,
                allServices: true
            };
        }
        const origItem = angular.copy(item);

        $mdDialog.show({
            templateUrl: 'app/discounts/item.html',
            clickOutsideToClose: false,
            locals: {
                item: item
            },
            controller: ($scope, $mdDialog, discounts, clients, item) => {
                $scope.codeLength = 10;
                $scope.edit = typeof item._id !== 'undefined';
                $scope.item = item;
                $scope.clients = [];
                $scope.clientName = '';
                const origItem = angular.copy(item);

                if ($scope.edit) {
                    $scope.item.service = $scope.item.service !== null ? $scope.item.service : 'all';
                }

                $scope.datePicker = {
                    placeholder: new Date(),
                    options: {
                        timezone: 'Europe/Paris'
                    },
                    dateFromMin: new Date(),
                    dateToMin: new Date(),
                    onChange: () => {
                        let dateFrom = moment($scope.item.validFrom),
                                dateTo = moment($scope.item.validTo);
                        $scope.datePicker.dateToMin = new Date(dateFrom);
                        if (dateTo.isBefore(dateFrom)) {
                            dateTo = dateFrom;
                        }
                        if (dateFrom.isAfter(dateTo)) {
                            dateFrom = dateTo;
                        }
                        $scope.item.validFrom = dateFrom.format(config.date.defaultFormat);
                        $scope.item.validTo = dateTo.format(config.date.defaultFormat);
                    }
                };

                $scope.clientSelect = {
                    type: $scope.item.allClients ? 'all' : ($scope.item.ClientId ? 'client' : 'type'),
                    onChange: () => {
                        let type = $scope.clientSelect.type;
                        switch (type) {
                            case 'client':
                                $scope.item.allClients = false;
                                $scope.item.clientType = null;
                                break;
                            case 'all':
                            case 'type':
                                $scope.item.allClients = type === 'all';
                                $scope.item.ClientId = null;
                                delete $scope.item.client;
                                if (type === 'all') {
                                    $scope.item.clientType = null;
                                }
                                break;
                        }
                    }
                };
                $scope.serviceSelect = {
                    onChange: () => {
                        /*let item = $scope.item;
                         if (item.service === 'all') {
                         item.allServices = true;
                         }*/
                    }
                };

                $scope.generateCode = (len = 10) => {
                    const chars = 'ABCDEFGHJKLMNPRSTVWXYZ0123456789';
                    if (len < 4) {
                        len = $scope.codeLength = 10;
                    }
                    let code = '';
                    for (let i = 0; i < len; i++) {
                        code += chars.charAt(Math.floor(Math.random() * chars.length));
                    }
                    $scope.item.code = code;
                };

                $scope.searchClient = () => {
                    if ($scope.clientName.length < 3) {
                        return;
                    }
                    return clients.get({
                        name: $scope.clientName
                    }).then((result) => {
                        $scope.clients = result.items;
                    }).catch((err) => {
                        console.error(err);
                    });
                };

                $scope.closeDialog = (validated = false, form = null) => {

                    if (validated) {
                        let item = $scope.item,
                                type = $scope.clientSelect.type;
                        item.allClients = type === 'all';
                        switch (type) {
                            case 'client':
                                if (!$scope.item.client) {
                                    return toast.show('Veuillez sélectionner le client' +
                                            ' concerné par le code de réduction.', 'error');
                                }
                                item.clientType = null;
                                item.ClientId = $scope.item.client._id;
                                break;
                            case 'type':
                                if (typeof item.clientType === undefined) {
                                    console.log('vous devez sélectionner un type de client');
                                    return;
                                }
                                break;
                            case 'all':
                                item.clientType = null;
                                item.ClientId = null;
                        }

                        if (item.service === 'all') {
                            item.service = null;
                            item.allServices = true;
                        } else {
                            item.allServices = false;
                        }
                    }

                    if (!$scope.edit) { // adding a new discount
                        if (!validated) {
                            $mdDialog.cancel();
                        } else {
                            if (!form.$valid) {
                                return;
                            }
                            $mdDialog.hide(discounts.add(item));
                        }
                        return;
                    }
                    const isChanged = !isEqualTo(item, origItem);
                    if (!validated) {
                        if (isChanged) {
                            for (let attr in origItem) {
                                $scope.item[attr] = origItem[attr];
                            }
                        }
                        $mdDialog.cancel();
                    } else {
                        if (!form.$valid) {
                            return;
                        }
                        if (isChanged) {
                            $mdDialog.hide(discounts.update(item));
                        } else {
                            $mdDialog.hide();
                        }
                    }//
                };
            }
        }).then((res) => {
            if (res) {
                toast.show('Le code de réduction a bien été ' +
                        (item._id ? 'mis à jour' : 'ajouté') + '.', 'success');
                getDiscounts();
            }
        }).catch((err) => {
            if (err) {
                if (index) {
                    $scope.discounts[index] = origItem;
                }
                toast.show(
                        err && err.message
                        ? err.message
                        : 'Une erreur est survenue lors de traitement de votre demande',
                        'error',
                        true
                        );
            }
        });
    };

    function isEqualTo(obj1, obj2) {
        let yes = true;
        for (let attr in obj1) {
            console.log(attr + ' = ' + (obj1[attr] === obj2[attr]));
            yes = yes && obj1[attr] === obj2[attr];
        }
        return yes;
    }

    $scope.showDeleteConfirm = (item) => {
        $mdDialog.show({
            parent: angular.element(document.body),
            template:
                    `<md-dialog layout-padding flex="35">
                    <md-dialog-content layout="row" layout-wrap>
                        <div flex='100'>
                            <h3 style='margin: 0; margin-bottom: 10px;'>
                                <md-icon class='f24 cf00'>warning</md-icon> Suppression
                            </h3>
                            <md-divider></md-divider><br>
                        </div>
                        <div>
                            <p>Voulez-vous vraiment supprimer le code de réduction "<b>` + item.code + `</b>" ?</p>
                            <p>Cette opération sera exécutée immédiatement et elle est <b>irréversible</b> !</p>
                        </div>
                    </md-dialog-content>
                    <md-dialog-actions>
                        <div class='buttons-container'>
                            <md-button ng-click="closeDialog()"
                                class="md-button green btn">Annuler</md-button>
                            <md-button ng-click="closeDialog(true)" 
                                class="btn btn-submit red">Confirmer la suppression</md-button>
                        </div>
                    </md-dialog-actions>
                  </md-dialog>`,
            locals: {
                itemId: item._id
            },
            controller: ($scope, $mdDialog, toast, discounts, itemId) => {
                $scope.closeDialog = (confirm = false) => {
                    $mdDialog.hide();
                    if (confirm) {
                        discounts.delete(itemId).then(() => {
                            toast.show('Le code de réduction a bien été supprimé.', 'success');
                            getDiscounts();
                        }).catch((err) => {
                            toast.show('Une erreur est survenu lors de la suppression' +
                                    ' de ce discount.');
                        });
                    }//
                };
            }
        });
    };

    function getDiscounts() {
        let params = {};
        if ($scope.pagination.filters) {
            for (let i in $scope.pagination.filters) {
                if (i === 'date') {
                    params[i] = moment($scope.pagination.filters[i]).format(config.date.defaultFormat);
                } else {
                    params[i] = $scope.pagination.filters[i];
                }
            }
        }
        discounts.get(params, $scope.pagination.currentPage,
                $scope.pagination.itemsPerPage).then((data) => {
            $scope.discountsCount = data.count;
            $scope.discounts = data.items;
            for (let i = 0; i < $scope.discounts.length; i++) {
                let discount = $scope.discounts[i];
                discount.serviceLabel = procedureTools.getProcedureName(discount.service);
                discount.clientName = procedureTools.getPersonName(discount.Client);
                let dateFrom = moment(discount.validFrom),
                        dateTo = moment(discount.validTo),
                        now = moment();
                discount.isValid = now.isBetween(dateFrom, dateTo) &&
                        discount.maxUses > discount.uses;
                if (discount.ClientId !== null) {
                    discount.client = discount.Client;
                }
            }
            $scope.pagination.totalItems = data.count;
            $scope.$apply();
        });
    }

    // first, start by getting discounts
    getDiscounts();

});